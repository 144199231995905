import React from "react"
import { graphql } from "gatsby"
import { CardColumns } from 'react-bootstrap';

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import ActualiteCard from "../../components/actualitecard";

const Actualites = ({ location, data }) => {
	const actualites = data.allGraphCmsActualite.nodes.reverse().map((actualite) => <ActualiteCard actualite={actualite} key={actualite.slug} />)
	return (
		<Layout location={location}>
			<SEO title="Actualités" />
			<CardColumns>
				{actualites}
			</CardColumns>
		</Layout>
	)
}

export default Actualites

export const ActualitesQuery = graphql`
query ActualitesQuery {
	allGraphCmsActualite {
		nodes {
			contenu {
				markdownNode {
					childMarkdownRemark {
						excerpt
					}
				}
			}
			imageHeader {
				localFile {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED),
					}
				}
			}
			...ActualiteData
		}
	}
}
`


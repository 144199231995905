import React from "react"

import { Card } from "react-bootstrap" 
import { GatsbyImage } from "gatsby-plugin-image"

import { Link } from "gatsby"

import publicationDateRendererHOC from "./publication-date-rendererHOC"

const ActualiteCard = ({ displayData, publicationDate }) => {
	const {
		slug,
		imageHeader,
		titre,
		contenu,
	} = displayData;
	return (
		<Card id={slug}>
			{imageHeader &&
				<div className="card">
					<GatsbyImage alt="image actualité" className="card-img-top" image={imageHeader.localFile.childImageSharp.gatsbyImageData} />
				</div>
			}
			<Card.Body>
				<Card.Title style={{ fontSize: '22px', textTransform: 'uppercase' }}>{titre}</Card.Title>
				<Card.Subtitle className="mb-2" style={{ fontSize: '14px', fontStyle: 'italic', color: 'lightGrey' }}>
					{`publié le ${publicationDate}`}
				</Card.Subtitle>
				<Card.Text>
					{contenu.markdownNode.childMarkdownRemark.excerpt}
				</Card.Text>
			</Card.Body>
			<Card.Footer>
				<div style={{ textAlign: 'center' }}>
					<Link to={"/actualites/" + slug} className="btn btn-primary btn-green btn-sm" style={{ background: '#328925' }}>En savoir plus</Link>
				</div>
			</Card.Footer>
		</Card>
	)
}

export default publicationDateRendererHOC(ActualiteCard, 'actualite')

